module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unifyd Finance | The World's First DeFi Ecosystem","short_name":"Unifyd","start_url":"/","lang":"en","background_color":"#101010","theme_color":"#2196f3","display":"standalone","icon":"static/icons-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"688c7460e346a3e8e50ba4eb4c9f5016"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
